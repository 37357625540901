import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex';
import { IPaginate, PayloadState, RootState } from '@/types/types';
import createPersistedState from 'vuex-persistedstate';
import { auth } from '@/store/modules/auth';
import { users } from '@/store/modules/users';
import SecureLS from 'secure-ls';
import { products } from '@/store/modules/products';
import { ussdCodes } from '@/store/modules/ussd-codes';
import { navigation } from '@/store/modules/navigation';
import { customers } from '@/store/modules/customers';
import { AppConstants } from '@/constants/app.constants';
import { dashboard } from '@/store/modules/dashboard';
import { logs } from '@/store/modules/logs';
import { transactions } from '@/store/modules/transactions';
import { organizations } from '@/store/modules/organizations';

let ls = new SecureLS({
  isCompression: false,
});

Vue.use(Vuex);

const mutations: MutationTree<RootState> = {
  UPDATE_SNACKBAR_MSG(state, payload: string) {
    state.snackBar!.message = payload;
  },
  UPDATE_SNACKBAR_VISIBILITY(state, payload: boolean) {
    state.snackBar!.show = payload;
  },
  UPDATE_ISLOADING(state, payload: boolean) {
    state.isLoading = payload;
  },
  UPDATE_ISPAGELOADING(state, payload: boolean) {
    state.isPageLoading = payload;
  },
  UPDATE_ISDIALOGLOADING(state, payload: boolean) {
    state.isDialogLoading = payload;
  },
  UPDATE_DIALOG(state, payload: PayloadState) {
    state.dialogs[payload.idx] = payload.state;
  },
  UPDATE_INTERNET_STATE(state, payload: boolean) {
    state.internet = payload;
  },
  UPDATE_RESET_FORM(state, payload: boolean) {
    state.resetForm = payload;
  },
  UPDATE_PAGE(state, payload: IPaginate) {
    state.paginate.page = payload.page;
    state.paginate.limit = payload?.itemsPerPage ?? 15;
  },
  UPDATE_CURRENT_PAGE(state, payload: IPaginate) {
    state.paginate.currentPage = payload?.page ?? 1;
  },
  UPDATE_NOT_FOUND(state, payload: boolean) {
    state.notFound = payload;
  },
  UPDATE_NAVIGATION_DRAWER(state, payload: boolean) {
    state.navigationDrawer = payload;
  },
};

const actions: ActionTree<RootState, RootState> = {
  snackBarMessage({ commit }, payload: boolean) {
    commit('UPDATE_SNACKBAR_MSG', payload);
  },
  snackBarVisibility({ commit }, payload: boolean) {
    commit('UPDATE_SNACKBAR_VISIBILITY', payload);
  },
  isLoading({ commit }, payload) {
    commit('UPDATE_ISLOADING', payload);
  },
  isPageLoading({ commit }, payload) {
    commit('UPDATE_ISPAGELOADING', payload);
  },
  updateDialog({ state, commit }, payload: PayloadState) {
    commit('UPDATE_DIALOG', payload);
    if (!['image', 'delete'].includes(payload.idx)) {
      commit('UPDATE_ISDIALOGLOADING', true);
    }
  },
  internet({ commit }, payload: boolean) {
    commit('UPDATE_INTERNET_STATE', payload);
  },
  isDialogLoading({ commit }, payload: boolean) {
    commit('UPDATE_ISDIALOGLOADING', payload);
  },
  resetFormValues({ commit }, payload: boolean) {
    commit('UPDATE_RESET_FORM', payload);
  },
  paginate({ commit }, payload) {
    commit('UPDATE_PAGE', payload);
  },
  currentPage({ commit }, payload) {
    commit('UPDATE_CURRENT_PAGE', payload);
  },
  notFound({ commit }, payload) {
    commit('UPDATE_NOT_FOUND', payload);
  },
  navigationDrawer({ commit }, payload: boolean) {
    commit('UPDATE_NAVIGATION_DRAWER', payload);
  },
};

const getters: GetterTree<RootState, RootState> = {
  getIsLoading: (state): boolean => state.isLoading,
  getIsPageLoading: (state): boolean => state.isPageLoading,
  getIsDialogLoading: (state): boolean => state.isDialogLoading,
  getSnackBarMessage: (state): string => state.snackBar!.message,
  showSnackBar: (state): boolean => state.snackBar!.show,
  getDialogState: state => (id: string) => state.dialogs[id],
  getInternet: (state): boolean => state.internet,
  getResetFormState: (state): boolean => state.resetForm,
  getPagination: state => state.paginate,
  getCurrentPage: state => state.paginate.currentPage,
  getNotFound: state => state.notFound,
  getNavigationDrawerState: state => state.navigationDrawer,
};

/**
 * Vuex store initial state
 */
const store: StoreOptions<RootState> = {
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      key: AppConstants.LOCAL_STORAGE_KEY,
      paths: ['auth.user', 'navigation.all', 'navigation.navigationKey'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key),
      },
    }),
  ],
  state: {
    isLoading: false,
    isPageLoading: false,
    isDialogLoading: false,
    snackBar: {
      message: '',
      show: false,
    },
    timeout: 2000,
    internet: false,
    dialogs: {
      add: false,
      edit: false,
      delete: false,
      details: false,
    },
    resetForm: false,
    paginate: {
      currentPage: 1,
      page: 0,
      limit: 15,
    },
    notFound: false,
    navigationDrawer: false,
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {
    auth,
    users,
    products,
    ussdCodes,
    navigation,
    customers,
    dashboard,
    logs,
    transactions,
    organizations,
  },
};

export default new Vuex.Store<RootState>(store);
