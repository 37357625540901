import { IDialogAndButtonAction, IProduct, IUSSDServiceCodes, RootState, StoreState } from '@/types/types';
import { ActionTree, GetterTree, Module, ModuleTree, MutationTree } from 'vuex';
import { ProductsService } from '@/services/products.service';
import { isNetworkError } from '@/utils/helpers';
import { UssdCodesService } from '@/services/ussd-codes.service';

const state: StoreState<IUSSDServiceCodes> = {
  all: [],
  details: {
    id: '',
    code: '',
    apiUrl: '',
    description: '',
    consumer: '',
    status: '',
  },
  reset: {
    id: '',
    code: '',
    apiUrl: '',
    description: '',
    consumer: '',
    status: '',
  },
};

const mutations: MutationTree<StoreState<IUSSDServiceCodes>> = {
  UPDATE_USSD_SERVICE_CODES(state, payload: Array<IUSSDServiceCodes>) {
    state.all = payload;
  },
  UPDATE_USSD_SERVICE_DETAILS(state, payload: IUSSDServiceCodes) {
    state.details = payload;
  },
};

const actions: ActionTree<StoreState<IUSSDServiceCodes>, RootState> = {
  async list({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = await UssdCodesService.list();
      if (response$) {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_USSD_SERVICE_CODES', response$.data);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, id: string) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = await UssdCodesService.details(id);
      if (response$) {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_USSD_SERVICE_DETAILS', response$.data);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async create({ commit, dispatch }, payload: IDialogAndButtonAction<IUSSDServiceCodes>) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await UssdCodesService.create(payload.body);
      if (response$) {
        dispatch('updateDialog', payload.loading, { root: true });
        dispatch('resetFormValues', true, { root: true });
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('snackBarMessage', 'USSD Service code added successfully', {
          root: true,
        });
        dispatch('list');
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async update({ commit, dispatch }, payload: IDialogAndButtonAction<IUSSDServiceCodes>) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await UssdCodesService.update(Number(payload.body.id), payload.body);
      if (response$) {
        dispatch('updateDialog', payload.loading, { root: true });
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('snackBarMessage', 'USSD Service code updated successfully', {
          root: true,
        });
        dispatch('list');
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<StoreState<IUSSDServiceCodes>, RootState> = {
  getAllUssdServiceCodes: state => state.all,
  getUssdServiceCodesDetails: state => state.details,
  getResetFormValues: state => state.reset,
};

export const ussdCodes: Module<StoreState<IUSSDServiceCodes>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
