import { Request } from '@/services/api';
import { Api } from '@/services/externalApi';
import { configs } from '@/services/credit-union.service';

const DashboardService = {
  sms: {
    statistics() {
      return Request().get(`/admin/customers/dashboard`);
    },
    graph(filter?: string) {
      return Request().get(`/admin/customers/graph${filter ? filter : ''}`);
    },
    balance() {
      return Request().get(`/admin/credit/balance`);
    },
  },
  cu: {
    statistics() {
      return Api(configs().baseUrl, configs().config).get(`/admin/reports/total/list`);
    },
    graph(query?: string) {
      return Api(configs().baseUrl, configs().config).get(`/admin/reports/graph${query ? query : ''}`);
    },
  },
};

export { DashboardService };
