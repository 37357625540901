import { RootState, StoreState } from '@/types/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { isNetworkError } from '@/utils/helpers';
import { DashboardService } from '@/services/dashboard.service';

interface IDashboard {}

const state: StoreState<IDashboard> = {
  all: [],
  smsStatistics: {},
  smsGraph: {},
  cuStatistics: {},
  cuGraph: {},
  balance: 0,
};

const mutations: MutationTree<StoreState<IDashboard>> = {
  UPDATE_SMS_STATISTICS(state, payload: any) {
    state.smsStatistics = payload;
  },
  UPDATE_SMS_GRAPH(state, payload: any) {
    state.smsGraph = payload;
  },
  UPDATE_CU_STATISTICS(state, payload: any) {
    state.cuStatistics = payload;
  },
  UPDATE_CU_GRAPH(state, payload: any) {
    state.cuGraph = payload;
  },
  UPDATE_CREDIT_BALANCE(state, payload: number) {
    state.balance = payload;
  },
};

const actions: ActionTree<StoreState<IDashboard>, RootState> = {
  async smsCreditBalance({ commit, dispatch }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response = await DashboardService.sms.balance();
      if (response) {
        dispatch('isLoading', false, { root: true });
        commit('UPDATE_CREDIT_BALANCE', response?.data?.balance);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async dashboardStatistics({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response = await DashboardService.sms.statistics();
      if (response) {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_SMS_STATISTICS', response?.data);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async dashboardGraph({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response = await DashboardService.sms.graph();
      if (response) {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_SMS_GRAPH', response.data);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async creditUnionDashboardStatistics({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response = await DashboardService.cu.statistics();
      if (response) {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_CU_STATISTICS', response?.data?.data);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async creditUnionDashboardGraph({ commit, dispatch }, query: any) {
    try {
      dispatch('isLoading', true, { root: true });
      const response = await DashboardService.cu.graph(query);
      if (response) {
        dispatch('isLoading', false, { root: true });
        commit('UPDATE_CU_GRAPH', response.data?.data);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<StoreState<IDashboard>, RootState> = {
  getSMSDashboardStatistics: state => state.smsStatistics,
  getSMSDashboardGraph: state => state.smsGraph,
  getCUDashboardStatistics: state => state.cuStatistics,
  getCUDashboardGraph: state => state.cuGraph,
  getSMSCreditBalance: state => state.balance,
};

export const dashboard: Module<StoreState<IDashboard>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
