import { ILogs, IPaginate, RootState, StoreState } from '@/types/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ProductsService } from '@/services/products.service';
import { isNetworkError } from '@/utils/helpers';
import { CustomersService } from '@/services/customers.service';

const state: StoreState<ILogs> = {
  all: [],
  paginate: {
    total: 0,
    page: 1,
    pages: 1,
    itemsPerPage: 15,
  },
  details: undefined,
};

const mutations: MutationTree<StoreState<ILogs>> = {
  UPDATE_LIST(state, payload: Array<ILogs>) {
    state.all = payload;
  },
  UPDATE_PAGINATION(state, payload: IPaginate) {
    state.paginate = payload;
  },
  UPDATE_DETAILS(state, payload: ILogs) {
    state.details = payload;
  },
};

const actions: ActionTree<StoreState<ILogs>, RootState> = {
  async list({ commit, dispatch }, query: string) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = await CustomersService.listAllLogs(query);
      if (response$) {
        dispatch('isPageLoading', false, { root: true });
        dispatch('internet', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
        const { docs, page, pages, total, limit: itemsPerPage } = response$.data.paginateObj;
        commit('UPDATE_LIST', docs);
        commit('UPDATE_PAGINATION', { page, total, itemsPerPage, pages });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async loadMore({ dispatch, commit, state }, query: string) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await CustomersService.listAllLogs(query);
      if (response$) {
        const { page, limit, pages, total, docs } = response$.data.paginateObj;
        commit('UPDATE_LIST', [...state.all, ...docs]);
        commit('UPDATE_PAGINATION', {
          page,
          pages,
          total,
          itemsPerPage: limit,
        });
        dispatch('isLoading', false, { root: true });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, id: number) {
    try {
      dispatch('isDialogLoading', false, { root: true });
      const response$ = await CustomersService.getLogDetails(id);
      if (response$) {
        dispatch('isDialogLoading', false, { root: true });
        commit('UPDATE_DETAILS', response$.data);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async downloadCustomerLogs({ dispatch }, payload: { id: number; query: string; ext: string; fileName: string }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await CustomersService.downloadLogsForCustomers(payload.id, payload.query);
      if (response$) {
        dispatch('isLoading', false, { root: true });
        const { fileName, ext } = payload;
        const blob = new Blob([response$.data], {
            type: 'application/octet-stream',
          }),
          a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = `${fileName}-sms-logs.${ext}`;
        a.click();
        URL.revokeObjectURL(a.href);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<StoreState<ILogs>, RootState> = {
  getAllLogs: state => state.all,
  getPagination: state => state.paginate,
  getLogDetails: state => state.details,
};

export const logs: Module<StoreState<ILogs>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
