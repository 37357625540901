// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyAvNIDjp1U_-pJoeEKhUu0TJNgy3wBsM6I',
  authDomain: 'bizaccess-6fe17.firebaseapp.com',
  databaseURL: 'https://bizaccess-6fe17.firebaseio.com',
  projectId: 'bizaccess-6fe17',
  storageBucket: 'bizaccess-6fe17.appspot.com',
  messagingSenderId: '783370528400',
  appId: '1:783370528400:web:f7b4de1ab6f37f46a3da15',
  measurementId: 'G-9YJ14D6988',
};
