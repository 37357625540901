import { Request } from '@/services/api';

export class SenderIdService {
  static list(query: string) {
    return Request().get(`/admin/sender-ids${query}`);
  }
  static approve(id: number, payload: { isApproved: boolean }) {
    return Request().put(`/sender-id/${id}/approve/by/admin`, payload);
  }
}
