import { Api, IConfig } from '@/services/externalApi';
import { AppConstants } from '@/constants/app.constants';
import { IDefaultUser } from '@/types/types';
import qs from 'qs';
import { IOrganizations } from '../types/types';
import SecureLS from 'secure-ls';
let ls = new SecureLS({
  isCompression: false,
});
let session: string = '';
try {
  session = JSON.parse(ls.get('cloud.access'))?.auth?.user?.token;
  // eslint-disable-next-line no-empty
} catch (e) {}
export const configs = (): { baseUrl: string; config: IConfig } => {
  return {
    baseUrl: AppConstants.CREDIT_UNION_API_URL,
    config: {
      apiKey: process.env.VUE_APP_CU_X_API_KEY,
      apiServiceCode: process.env.VUE_APP_CU_X_SERVICE_CODE,
    },
  };
};

export class CreditUnionService {
  static list(query: string) {
    return Api(configs().baseUrl, configs().config).get(`/admin/organizations${query}`);
  }

  static addDefaultUser(id: string, payload: IDefaultUser) {
    return Api(configs().baseUrl, configs().config).post(`/admin/organizations/${id}/default/user`, payload);
  }

  static activateAccount(id: string, payload: { isActive: boolean }) {
    return Api(configs().baseUrl, configs().config).patch(`/admin/organizations/${id}/activate`, payload);
  }

  static transactions(query: string) {
    const options = qs.parse(query);
    return Api(configs().baseUrl, configs().config).get(`/public/organizations/transactions/all${query}`, {
      responseType: options?.download ? 'blob' : 'json',
    });
  }

  static transactionDetails(id: string) {
    return Api(configs().baseUrl, configs().config).get(`/public/transactions/${id}`);
  }

  static organizations(isPaginated: boolean) {
    return Api(configs().baseUrl, configs().config).get(`/admin/organizations?isPaginated=${isPaginated}`);
  }

  static organizationDetails(id: string) {
    return Api(configs().baseUrl, configs().config).get(`/admin/organizations/${id}`);
  }

  static createOrganization(payload: IOrganizations) {
    return Api(configs().baseUrl, configs().config).post(`/admin/organizations`, payload);
  }

  static updateOrganization(id: string, payload: IOrganizations) {
    return Api(configs().baseUrl, configs().config).put(`/admin/organizations/${id}`, payload);
  }

  static addSMSCredentials(id: string, payload: any) {
    return Api(configs().baseUrl, configs().config).post(`/admin/organizations/${id}/sms/keys`, payload);
  }

  static downloadUSSDAccessReport(query: string) {
    const options = qs.parse(query);
    return Api(configs().baseUrl, configs().config).get(`/public/organizations/access-count/reports?${query}`, {
      responseType: options?.download ? 'blob' : 'json',
    });
  }

  static listAllPayments(query: string, opts?: any) {
    const options = qs.parse(query);
    return Api(configs().baseUrl, configs().config).get(`/public/all/admin/payments${query}`, {
      responseType: options?.download ? 'blob' : 'json',
      ...(session && {
        headers: {
          Authorization: `Bearer ${session}`,
        },
      }),
    });
  }

  static listAllMerchants(query: string, opts?: any) {
    return Api(configs().baseUrl, configs().config).get(`/public/organizations/admin/merchant${query}`, {
      ...(session && {
        headers: {
          Authorization: `Bearer ${session}`,
        },
      }),
    });
  }
}
