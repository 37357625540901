import Axios from 'axios';

export interface IConfig {
  apiKey: string;
  apiServiceCode: string;
}

export const Api = (baseUrl?: string, configs?: IConfig) => {
  return Axios.create({
    baseURL: `${baseUrl}/api/v1`,
    timeout: 80000,
    headers: {
      'x-api-key': configs?.apiKey,
      'x-service-code': configs?.apiServiceCode,
    },
  });
};

export const ReportApi = (baseUrl: string) =>
  Axios.create({
    baseURL: baseUrl,
    timeout: 80000,
  });
