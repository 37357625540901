import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import { firebaseConfig } from '@/config/firebase';
import Clipboard from 'vue-clipboard2';

require('./assets/css/main.css');
Vue.use(Clipboard);
Vue.component('apexcharts', VueApexCharts);
Vue.config.productionTip = false;

firebase.initializeApp(firebaseConfig);
// Notification.requestPermission()
//   .then((response) => {
//     console.log(response);
//   })
//   .catch((error) => console.log(error));
// // firebase messaging
// const messaging = firebase.messaging();
// messaging
//   .getToken({
//     vapidKey:
//       "BHZ9z40S9gn7jDIdIPN3SWNhq-ImV9R2ZAgSgoYgPMpZB0kotY1zt5iFg7sEhRI8QhPtzK2inB3hBCPiJ8mg8wg"
//   })
//   .then((currentToken) => {
//     if (currentToken) {
//     } else {
//       console.log("request permission ");
//     }
//   })
//   .catch((error) => {
//
//   });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
