import {
  ICalculatedValues,
  ICustomers,
  IDefaultUser,
  IPaginate,
  ISenderID,
  IUser,
  RootState,
  StoreState,
} from '@/types/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { isNetworkError } from '@/utils/helpers';
import { CustomersService } from '@/services/customers.service';
import { SenderIdService } from '@/services/sender-id.service';
import { CreditUnionService } from '@/services/credit-union.service';

const state: StoreState<ICustomers> = {
  all: [],
  details: {
    id: '',
    name: '',
    isActive: '',
    email: '',
    phone: '',
    isSubAccount: false,
  },
  paginate: {
    total: 0,
    page: 1,
    itemsPerPage: 15,
  },
  calculatedValues: {},
  senderIDs: [],
};
const mutations: MutationTree<StoreState<ICustomers>> = {
  UPDATE_CUSTOMER_LIST(state, payload: Array<ICustomers>) {
    state.all = payload;
  },
  UPDATE_CUSTOMER_DETAILS(state, payload: ICustomers) {
    state.details = payload;
  },
  UPDATE_PAGINATION(state, payload: IPaginate) {
    state.paginate = payload;
  },
  UPDATE_CALCULATED_VALUE(state, payload) {
    state.calculatedValues = payload;
  },
  UPDATE_SENDER_ID_LIST(state, payload: Array<ISenderID>) {
    state.senderIDs = payload;
  },
};

const actions: ActionTree<StoreState<ICustomers>, RootState> = {
  async list({ commit, dispatch }, query: string) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = await CustomersService.list(query);
      if (response$) {
        dispatch('isPageLoading', false, { root: true });
        dispatch('internet', false, { root: true });
        const { docs, page, total, limit: itemsPerPage } = response$.data.paginateObj;
        commit('UPDATE_CUSTOMER_LIST', docs);
        commit('UPDATE_PAGINATION', { page, total, itemsPerPage });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async cacheCustomerDetails({ commit }, payload: ICustomers) {
    if (payload) {
      commit('UPDATE_CUSTOMER_DETAILS', payload);
    }
  },
  async activateAccount({ dispatch }, payload: { id: number; body: { isActive: boolean } }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await CustomersService.activateAccount(payload.id, payload.body);
      if (response$) {
        dispatch('isLoading', false, { root: true });
        dispatch('internet', false, { root: true });
        dispatch('list', '?page=1&size=15');
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async smsCalculator({ dispatch, commit }, query: string) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await CustomersService.smsCalculator(query);
      if (response$) {
        commit('UPDATE_CALCULATED_VALUE', response$.data);
        dispatch('isLoading', false, { root: true });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async topUpCustomerAccount(
    { dispatch, commit },
    payload: { id: number; body: ICalculatedValues & { calculatedValue: number }; search: string },
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await CustomersService.topUpAccount(payload.id, payload.body);
      if (response$) {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('updateDialog', { idx: 'add', state: false }, { root: true });
        dispatch('resetFormValues', true, { root: true });
        dispatch('snackBarMessage', 'Top up done successfully', {
          root: true,
        });
        dispatch('list', `?page=1&size=15${payload?.search ? '&search=' + payload?.search : ''}`);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async sendForgotPasswordLinkToCustomer({ dispatch, commit }, payload: { email: string }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await CustomersService.forgotPassword(payload);
      if (response$) {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('snackBarMessage', 'Password reset link sent to customer successfully', {
          root: true,
        });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async listAllSenderIDs({ commit, dispatch }, query: string) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = await SenderIdService.list(query);
      if (response$) {
        dispatch('isPageLoading', false, { root: true });
        dispatch('internet', false, { root: true });
        const { docs, page, total, limit: itemsPerPage } = response$.data.paginateObj;
        commit('UPDATE_SENDER_ID_LIST', docs);
        commit('UPDATE_PAGINATION', { page, total, itemsPerPage });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async approveSenderID({ commit, dispatch }, payload: { id: number; body: { isApproved: boolean } }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await SenderIdService.approve(payload.id, payload.body);
      if (response$) {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('snackBarMessage', 'Sender ID approved successfully', {
          root: true,
        });
        dispatch('listAllSenderIDs', `?page=1&size=15&filter=ALL`);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },

  // CREDIT UNION
  async listCreditUnions({ commit, dispatch }, query: string) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response = await CreditUnionService.list(query);
      if (response) {
        dispatch('isPageLoading', false, { root: true });
        const { docs, page, totalItems, limit } = response.data.data;
        commit('UPDATE_CUSTOMER_LIST', docs);
        commit('UPDATE_PAGINATION', { page, total: totalItems, itemsPerPage: limit });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async addDefaultUser({ commit, dispatch, state }, payload: { id: string; body: IDefaultUser }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response = await CreditUnionService.addDefaultUser(payload.id, payload.body);
      if (response) {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('updateDialog', { idx: 'add', state: false }, { root: true });
        dispatch('listCreditUnions', `?page=1&size=${state.paginate.itemsPerPage}`);
        dispatch('resetFormValues', true, { root: true });
        dispatch('snackBarMessage', 'Default user added successfully', {
          root: true,
        });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async activateCreditUnionAccount({ dispatch, state }, payload: { id: string; body: { isActive: boolean } }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await CreditUnionService.activateAccount(payload.id, payload.body);
      if (response$) {
        dispatch('isLoading', false, { root: true });
        dispatch('internet', false, { root: true });
        dispatch('listCreditUnions', `?page=1&size=${state.paginate.itemsPerPage}`);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<StoreState<ICustomers>, RootState> = {
  getAllCustomers: state => state.all,
  getCustomerDetails: state => state.details,
  getPagination: state => state.paginate,
  getCalculatedValues: state => state.calculatedValues,
  getAllSenderIDs: state => state.senderIDs,
};

export const customers: Module<StoreState<ICustomers>, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
