import { ISideBar, RootState, StoreState } from '@/types/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { findNavigationLink } from '@/data/sidebar';

const state: StoreState<ISideBar | { label: string }> = {
  all: [],
  details: undefined,
  navigationKey: '',
  currentPageLink: '',
};

const mutations: MutationTree<StoreState<ISideBar | { label: string }>> = {
  UPDATE_LINKS(state, payload: Array<ISideBar | { label: string }>) {
    state.all = payload;
  },
  UPDATE_NAVIGATION_KEY(state, payload: string) {
    state.navigationKey = payload;
  },
  UPDATE_CURRENT_PAGE_LINK(state, payload: string) {
    state.currentPageLink = payload;
  },
};

const actions: ActionTree<StoreState<ISideBar | { label: string }>, RootState> = {
  setHomeLinks({ commit, state }, navigationKey: string) {
    if (state.all.length === 0) {
      commit('UPDATE_LINKS', findNavigationLink(navigationKey));
      commit('UPDATE_NAVIGATION_KEY', navigationKey);
    }
  },
  setAppLinks({ commit }, navigationKey: string) {
    commit('UPDATE_LINKS', findNavigationLink(navigationKey));
    commit('UPDATE_NAVIGATION_KEY', navigationKey);
  },
  setCurrentPageLink({ commit }, link: string) {
    commit('UPDATE_CURRENT_PAGE_LINK', link);
  },
};

const getters: GetterTree<StoreState<ISideBar | { label: string }>, RootState> = {
  getNavigationLinks: state => state.all,
  getNavigationKey: state => state.navigationKey,
};

export const navigation: Module<StoreState<ISideBar | { label: string }>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
