import { Request } from '@/services/api';
import { IEmailVerification, IResetPassword, LoginState } from '@/types/types';

class AuthService {
  /**
   * Endpoint for handling the login authentication and authorization
   * @param payload {Object}
   */
  static login(payload: LoginState) {
    return Request().post('/auth/login', payload);
  }

  static forgotPassword(payload: { email: string }) {
    return Request().post(`/auth/accounts/forgot-password`, payload);
  }

  static resetPassword(payload: IResetPassword) {
    return Request().post(`/auth/accounts/reset-password`, payload);
  }

  static changePassword(payload: { email: string; password: string; newPassword: string }) {
    return Request().post('/auth/accounts/change-password', payload);
  }

  static verifyAccount(payload: { code: string }) {
    return Request().get(`/auth/accounts/verification/${payload.code}`);
  }

  static me() {
    return Request().get(`/users/profile/me`);
  }
}

export default AuthService;
