import uuidv5 from 'uuid/v5';

/**
 * Function for generating uuid
 * @param domain
 * @return {*}
 */
const uuid = (domain: string) => {
  return uuidv5(domain, uuidv5.DNS);
};

/**
 * Function for generating app id for the applications
 * @param appName
 * @returns {string}
 */
const appid = (appName: string) => {
  let number = Math.floor(Math.random() * 999999 + 100000);
  let prefix = appName.substring(0, 3);
  let suffix = appName.substring(appName.length - 3);
  return `${prefix}-${number}-${suffix}`.toUpperCase();
};

const color = () => {
  return Math.floor(Math.random() * 999999 + 100000);
};
/**
 * Function to check whether a request error is a network error or axios error
 * @param err {*}
 * @returns {boolean|boolean}
 */
const isNetworkError = (err: { isAxiosError: any; response: any }): boolean => {
  return !!err.isAxiosError && !err.response;
};

/**
 * Function for rendering the views from the view folder
 * @param view
 * @returns {function(): *}
 */
const loadView = (view: string) => {
  return () => import(`@/views/${view}.vue`);
};

/**
 * Function for rendering the widgets from the components folder
 * @param view
 * @returns {function(): *}
 */
const loadWidget = (view: string) => {
  return () => import(`@/components/${view}.vue`);
};

/**
 * Converts a JavaScript value to a JavaScript Object Notation (JSON) string.
 * @param payload {Object}
 */
const toJson = (payload: any) => {
  return JSON.stringify(payload);
};
/**
 * Converts a JavaScript Object Notation (JSON) string into an object.
 * @param payload
 */
const fromJson = (payload: any) => {
  return JSON.parse(payload);
};

/**
 * Method for handling the pagination of the list of items
 * @param totalItems
 * @param limit
 * @return {number}
 */
const paginate = (totalItems: number, limit: number = 15) => {
  let itemCount = totalItems ? totalItems : 1;
  return Math.floor(itemCount / limit) + (itemCount % limit > 0 ? 1 : 0);
};

export const generateCode = (prefix: string) => {
  return `${prefix}-${Math.floor(1000000000 + Math.random() * 9000000000)}`;
};
export const currencyFormat = (value: number) => {
  return new Intl.NumberFormat('en-GB', {
    currency: 'GHS',
    style: 'currency',
    minimumFractionDigits: 2,
  }).format(value);
};

export const toBase64 = (file: File, callback: (file: ArrayBuffer | string | null | undefined) => any) => {
  let reader = new FileReader();
  reader.onload = ev => {
    callback(ev.target?.result);
  };
  reader.readAsDataURL(file);
};

export const toBase64Promise = (file: File) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onloadend = ev => {
      resolve(ev.target?.result);
    };
    reader.onerror = ev => {
      reject(ev);
    };
    reader.readAsDataURL(file);
  });
};

export const carriers = (number: string) => {
  let myCarrier: { account_number: string; bank_code: string } = {
    account_number: '',
    bank_code: '',
  };
  let listOfCarriers = [
    {
      '020|050': 'VOD',
    },
    {
      '026|027|056|057': 'ATL',
    },
    {
      '024|054|055': 'MTN',
    },
  ];

  if ((number && number.length === 10) || number.length === 13) {
    listOfCarriers.forEach(carrier => {
      Object.entries(carrier).map(item => {
        let response = new RegExp(item[0]).test(number.slice(0, 3));
        if (response) {
          myCarrier['account_number'] = number;
          myCarrier['bank_code'] = item[1] ?? '0';
        }
      });
    });
    return myCarrier;
  }
  return null;
};

export const blobToFile = (blob: Blob, fileName?: string): File => {
  let theBlob: any = blob;
  theBlob.lastModifiedDate = new Date();
  return theBlob;
};

export const apiUrlPrefix = process.env.NODE_ENV === 'production' ? '/v1' : '/v1';

export const cleanDateFormat = (text: string, replacer?: string): string => {
  return text?.replace(/-+/g, replacer ?? '');
};
export { uuid, appid, isNetworkError, color, loadView, loadWidget, toJson, fromJson, paginate };
