import { Request } from '@/services/api';
import { IProduct } from '@/types/types';

export class ProductsService {
  static list() {
    return Request().get(`/admin/products`);
  }

  static create(body: Pick<IProduct, 'name' | 'description' | "logo" | "url">) {
    return Request().post(`/admin/products`, body);
  }

  static uploadImages(id: string, body: any) {
    return Request().post(`/tasks/products/${id}/images/upload`, body);
  }

  static updateImages(id: string, body: any, quantity: number) {
    return Request().put(`/tasks/products/${id}/images/upload?quantity=${quantity}`, body);
  }

  static details(id: string) {
    return Request().get(`/admin/products/${id}`);
  }

  static update(
    id: string,
    body: Pick<
      IProduct,
      'name' | 'description' | 'logo' | 'url'
    >,
  ) {
    return Request().put(`/admin/products/${id}`, body);
  }

  static publish(payload: { id: string; isPublished: boolean }) {
    return Request().put(`/products/${payload.id}/publish`, payload);
  }

  static moveToTrash(payload: { id: string; isTrashed: boolean }) {
    return Request().put(`/products/${payload.id}/trash`, payload);
  }
  static moveToArchives(payload: { id: string; isArchived: boolean }) {
    return Request().put(`/products/${payload.id}/archive`, payload);
  }
  static trash() {
    return Request().get(`/products/trash/list`);
  }
  static archives() {
    return Request().get(`/products/archives/list`);
  }

  static deleteAll() {
    return Request().delete(`/products/clear/trash`);
  }
}
