import { Request } from '@/services/api';
import { IUSSDServiceCodes } from '@/types/types';
export class UssdCodesService {
  static list() {
    return Request().get(`/admin/ussd/service/codes`);
  }

  static details(id: string) {
    return Request().get(`/admin/ussd/service/codes/${id}`);
  }

  static create(payload: Pick<IUSSDServiceCodes, 'code' | 'description' | 'apiUrl' | 'consumer'>) {
    return Request().post(`/admin/ussd/service/codes`, payload);
  }

  static update(id: number, payload: IUSSDServiceCodes) {
    return Request().put(`/admin/ussd/service/codes/${id}`, payload);
  }
}
