import { ISideBar } from '@/types/types';

export const links: { [key: string]: Array<ISideBar | { label: string }> } = {
  home: [
    {
      label: 'General',
    },
    {
      link: 'dashboard',
      icon: 'widgets',
      text: 'Home',
      isLocked: false,
    },
    {
      link: 'products',
      icon: 'category',
      text: 'Products',
      isLocked: false,
    },
    {
      link: 'ussd.service.codes',
      icon: 'leak_add',
      text: 'USSD Codes',
      isLocked: false,
    },
    {
      link: 'payments',
      icon: 'pix',
      text: 'Payments',
      isLocked: false,
    },
    {
      link: 'commissions',
      icon: 'shopping_bag',
      text: 'Commissions',
      isLocked: false,
    },
    {
      link: 'bills',
      icon: 'credit_card',
      text: 'Bill Mgmt',
      isLocked: true,
    },
    {
      link: 'newsletters',
      icon: 'newspaper',
      text: 'Newsletters',
      isLocked: true,
    },
    {
      label: 'Tickets',
    },
    {
      link: 'tickets',
      icon: 'loyalty',
      text: 'All Tickets',
      isLocked: true,
    },
    {
      label: 'ACL',
    },
    {
      link: 'archives',
      icon: 'lock',
      text: 'Roles',
      isLocked: true,
    },
    {
      link: 'archives',
      icon: 'group',
      text: 'User Mgmt',
      isLocked: true,
    },
    {
      label: 'Settings',
    },
    {
      link: 'archives',
      icon: 'notifications',
      text: 'Notifications',
      isLocked: true,
    },
    {
      label: 'Help Center',
    },
    {
      link: 'archives',
      icon: 'feedback',
      text: 'Feedback',
      isLocked: true,
    },
    {
      link: 'archives',
      icon: 'bug_report',
      text: 'Bug Reports',
      isLocked: true,
    },
  ],
  sms: [
    {
      label: 'General',
    },
    {
      link: 'sms.dashboard',
      icon: 'widgets',
      text: 'Dashboard',
      isLocked: false,
    },
    {
      link: 'sms.customers',
      icon: 'people',
      text: 'Customers',
      isLocked: false,
    },
    {
      link: 'sms.sender-id.all',
      icon: 'pending_actions',
      text: 'Sender IDs',
      isLocked: false,
    },
    {
      label: 'Logs Mgmt',
    },
    {
      link: 'sms.logs',
      icon: 'rss_feed',
      text: 'All Logs',
      isLocked: false,
    },
  ],
  cu: [
    {
      label: 'General',
    },
    {
      link: 'cu.dashboard',
      icon: 'widgets',
      text: 'Dashboard',
      isLocked: false,
    },
    {
      link: 'cu.customers',
      icon: 'people',
      text: 'Businesses',
      isLocked: false,
    },
    {
      label: 'Reports',
    },
    {
      link: 'cu.transactions',
      icon: 'bar_chart',
      text: 'Transactions',
      isLocked: false,
    },
    {
      link: 'cu.ussd.access-counts',
      icon: 'switch_access_shortcut',
      text: 'USSD Access Count',
      isLocked: false,
    },
  ],
};

export const findNavigationLink = (key: string) => {
  return links[key];
};
