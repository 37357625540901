import { Request } from '@/services/api';
import { ICalculatedValues, IUSSDServiceCodes } from '@/types/types';
export class CustomersService {
  static list(query: string) {
    return Request().get(`/admin/customers${query ? query : ''}`);
  }
  static activateAccount(id: number, payload: { isActive: boolean }) {
    return Request().patch(`/admin/customers/${id}/activate`, payload);
  }
  static smsCalculator(query: string) {
    return Request().get(`/admin/sms/calculator?${query}`);
  }
  static topUpAccount(id: number, payload: ICalculatedValues & { calculatedValue: number }) {
    return Request().patch(`/admin/customers/${id}/top-up`, payload);
  }
  static forgotPassword(body: { email: string }) {
    return Request().post(`/admin/customers/forgot-password`, body);
  }
  static listAllLogs(query: string) {
    return Request().get(`/admin/logs${query}`);
  }
  static getLogDetails(id: number) {
    return Request().get(`/admin/logs/${id}`);
  }
  static getLogForCustomers(id: number, query: string) {
    return Request().get(`/admin/customers/${id}/logs${query}`);
  }
  static downloadLogsForCustomers(id: number, query: string) {
    return Request().get(`/admin/customers/${id}/logs/download${query ? query : ''}`, {
      responseType: 'blob',
    });
  }
}
