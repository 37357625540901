import qs from 'qs';
import { ReportApi } from './externalApi';

export class ReportingService {
  static listAllCommissions(query: string) {
    const options = qs.parse(query);
    return ReportApi(process.env.VUE_APP_REPORTING_BASE_URL).get(`/transactions/commissions${query ? query : ''}`, {
      responseType: options?.download ? 'blob' : 'json',
    });
  }
}
