import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/router/path';
import store from '@/store/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {
  const isUserLoggedIn = store.getters['auth/isAuthenticated'];
  store.dispatch('snackBarVisibility', false, { root: true }).then();
  store.dispatch('navigation/setCurrentPageLink', to.fullPath).then();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserLoggedIn) {
      store.dispatch('auth/logOut', null).then();
      next({
        path: '/signin',
        query: {
          redirect: to.fullPath,
        },
      });
    } else next();
  } else next();
});

export default router;
