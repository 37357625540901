import { IProduct, IUser, RootState, StoreState } from '@/types/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { isNetworkError } from '@/utils/helpers';
import { ProductsService } from '@/services/products.service';

const state: StoreState<IProduct> = {
  all: [],
  details: {
    id: '',
    logo: '',
    url: '',
    uuid: '',
    slug: '',
    description: '',
    name: '',
    appSubPath: '',
  },
};

const mutations: MutationTree<StoreState<IProduct>> = {
  UPDATE_LIST(state, payload: Array<IProduct>) {
    state.all = payload;
  },
  UPDATE_DETAILS(state, payload: IProduct) {
    state.details = payload;
  },
};

const actions: ActionTree<StoreState<IProduct>, RootState> = {
  async list({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = await ProductsService.list();
      if (response$) {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_LIST', response$.data);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, payload: Pick<any, 'id'>) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = await ProductsService.details(payload.id);
      if (response$) {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_DETAILS', response$.data);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async create({ commit, dispatch }, payload: IProduct) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await ProductsService.create(payload);
      if (response$) {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('snackBarMessage', 'Product created successfully', {
          root: true,
        });
        dispatch('list');
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async update({ commit, dispatch }, payload: { id: string; body: IProduct }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = await ProductsService.update(payload.body.id, payload.body);
      if (response$) {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('snackBarMessage', 'Product updated successfully', {
          root: true,
        });
        dispatch('list');
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<StoreState<IProduct>, RootState> = {
  getAllProducts: state => state.all,
  getProductDetails: state => state.details,
};

export const products: Module<StoreState<IProduct>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
