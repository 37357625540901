import { IPaginate, RootState, StoreState } from '@/types/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { cleanDateFormat, isNetworkError } from '@/utils/helpers';
import { CreditUnionService } from '@/services/credit-union.service';
import * as qs from 'qs';
import { ReportingService } from '@/services/reporting.service';
interface ITransactions {
  totalAmount: number;
  paymentStatus: string;
  reference: string;
  service: string;
  createdAt: string;
  accountNumber: string;
  organizationName: string;
  [key: string]: any;
}

const state: StoreState<ITransactions> = {
  all: [],
  payments: [],
  paginate: {
    total: 0,
    itemsPerPage: 15,
    page: 1,
  },
  details: {
    totalAmount: 0,
    paymentStatus: '',
    reference: '',
    service: '',
    createdAt: '',
    accountNumber: '',
    organizationName: '',
    overallTotalAmount: 0,
    message: '',
    via: '',
    channel: '',
    meta: {},
    callerMsisdn: '',
    commission: '',
    currentState: '',
    difference: 0,
    thirdPartyRefID: '',
  },
  totalCommission: 0,
};

const mutations: MutationTree<StoreState<ITransactions>> = {
  UPDATE_TRANSACTION_LIST(state, payload: Array<ITransactions>) {
    state.all = payload;
  },
  UPDATE_PAYMENTS(state, payload: any) {
    state.payments = payload;
  },
  UPDATE_PAGINATION(state, payload: IPaginate) {
    state.paginate = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_COMMISSION(state, commission: number) {
    state.totalCommission = commission;
  },
};
const actions: ActionTree<StoreState<ITransactions>, RootState> = {
  async listAllPayments({ commit, dispatch }, query: string) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const options = qs.parse(query, { ignoreQueryPrefix: true });
      const response$ = await CreditUnionService.listAllPayments(query);
      if (response$) {
        dispatch('isPageLoading', false, { root: true });
        if (!options?.download) {
          const { docs, page, totalPages, totalDocs, size } = response$.data?.data;
          commit('UPDATE_TRANSACTION_LIST', docs);
          commit('UPDATE_PAGINATION', {
            page,
            pages: totalPages,
            total: totalDocs,
            itemsPerPage: size,
          });
        } else {
          const blob = new Blob([response$.data], {
              type: 'application/octet-stream',
            }),
            a = document.createElement('a');
          a.download = `TX-${cleanDateFormat(options?.startDate as string)}${cleanDateFormat(
            options?.endDate as string,
          )}.${options.ext}`;

          a.href = URL.createObjectURL(blob);
          a.click();
          URL.revokeObjectURL(a.href);
        }
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async list({ commit, dispatch }, query: string) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const options = qs.parse(query, { ignoreQueryPrefix: true });
      const response$ = await CreditUnionService.transactions(query);
      if (response$) {
        dispatch('isPageLoading', false, { root: true });
        if (!options?.download) {
          const { docs, page, totalDocs, limit } = response$.data?.data;
          commit('UPDATE_TRANSACTION_LIST', docs);
          commit('UPDATE_PAGINATION', {
            page,
            total: totalDocs,
            itemsPerPage: limit,
          });
        } else {
          const blob = new Blob([response$.data], {
              type: 'application/octet-stream',
            }),
            a = document.createElement('a');
          a.download = `TX-${cleanDateFormat(options?.startDate as string)}${cleanDateFormat(
            options?.endDate as string,
          )}.${options.ext}`;

          a.href = URL.createObjectURL(blob);
          a.click();
          URL.revokeObjectURL(a.href);
        }
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async listAllCommissions({ commit, dispatch }, query: string) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const options = qs.parse(query, { ignoreQueryPrefix: true });
      const response$ = await ReportingService.listAllCommissions(query);
      if (response$) {
        dispatch('isPageLoading', false, { root: true });
        if (!options?.download) {
          const {
            paginateObj: { docs, page, total, pages, limit },
            others: { totalCommission },
          } = response$.data?.data;
          commit('UPDATE_TRANSACTION_LIST', docs);
          commit('UPDATE_PAGINATION', {
            page,
            total,
            itemsPerPage: limit,
            pages,
          });
          commit('UPDATE_COMMISSION', totalCommission);
        } else {
          const blob = new Blob([response$.data], {
              type: 'application/octet-stream',
            }),
            a = document.createElement('a');
          a.download = `TX-${cleanDateFormat(options?.startDate as string)}${cleanDateFormat(
            options?.endDate as string,
          )}.${options.ext}`;
          a.href = URL.createObjectURL(blob);
          a.click();
          URL.revokeObjectURL(a.href);
        }
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, id: string) {
    try {
      dispatch('isDialogLoading', true, { root: true });
      const { data } = await CreditUnionService.transactionDetails(id);
      if (data) {
        dispatch('isDialogLoading', false, { root: true });
        commit('UPDATE_DETAILS', data.data);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<StoreState<ITransactions>, RootState> = {
  getAllTransactions: state => state.all,
  getTransactionDetails: state => state.details,
  getPagination: state => state.paginate,
  getTotalCommission: state => state.totalCommission,
};

export const transactions: Module<StoreState<ITransactions>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
