import { IMerchants, IOrganizations, ISMSCredentials, RootState, StoreState } from '@/types/types';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { isNetworkError, cleanDateFormat } from '@/utils/helpers';
import { CreditUnionService } from '@/services/credit-union.service';
import qs from 'qs';

const state: StoreState<IOrganizations> = {
  all: [],
  details: undefined,
};

const mutations: MutationTree<StoreState<IOrganizations>> = {
  UPDATE_LIST(state, payload: Array<IOrganizations>) {
    state.all = payload;
  },
  UPDATE_DETAILS(state, payload: IOrganizations) {
    state.details = payload;
  },
};

const actions: ActionTree<StoreState<IOrganizations>, RootState> = {
  async listAllMerchants({ commit, dispatch }, query: string) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = await CreditUnionService.listAllMerchants(query);
      if (response$) {
        commit(
          'UPDATE_LIST',
          (response$.data?.data as Array<IMerchants>).map(({ _id, username }) => ({ _id, name: username })),
        );
        dispatch('isPageLoading', false, { root: true });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async list({ commit, dispatch }, query: boolean) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = await CreditUnionService.organizations(query);
      if (response$) {
        commit('UPDATE_LIST', response$.data?.data);
        dispatch('isPageLoading', false, { root: true });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, id: string) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = await CreditUnionService.organizationDetails(id);
      if (response$) {
        commit('UPDATE_DETAILS', response$.data?.data);
        dispatch('isPageLoading', false, { root: true });
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async create(
    { dispatch },
    payload: { organization: IOrganizations; sms: ISMSCredentials; smsCopy: ISMSCredentials; isUpdating: boolean },
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const { _id, name, emailAddress, contact } = payload.organization;
      const response$ = payload?.isUpdating
        ? await CreditUnionService.updateOrganization(_id, { name, emailAddress, contact } as any)
        : await CreditUnionService.createOrganization(payload.organization);
      if (response$) {
        const updateSMSCredentials =
          payload.sms.smsApiKey === payload.smsCopy.smsApiKey &&
          payload.sms.smsApiSecret === payload.smsCopy.smsApiSecret;
        const smsKeysResponse = !payload?.isUpdating
          ? await CreditUnionService.addSMSCredentials(response$?.data?.data?._id, payload.sms)
          : !updateSMSCredentials
          ? await CreditUnionService.addSMSCredentials(_id, payload.sms)
          : true;
        if (smsKeysResponse) {
          dispatch('isLoading', false, { root: true });
          dispatch('snackBarVisibility', true, { root: true });
          dispatch('snackBarMessage', 'Organization created successfully', {
            root: true,
          });
        }
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async downloadAccessCountReport({ dispatch }, query: any) {
    try {
      dispatch('isLoading', true, { root: true });
      const response = await CreditUnionService.downloadUSSDAccessReport(qs.stringify(query));
      if (response) {
        dispatch('isLoading', false, { root: true });
        const blob = new Blob([response?.data], {
            type: 'application/octet-stream',
          }),
          a = document.createElement('a');
        a.download = `ACCESS-REPORTS-${query?.orgName ?? ''}${cleanDateFormat(query?.startDate)}${cleanDateFormat(
          query?.endDate,
        )}.${query?.ext ?? 'xlsx'}`;
        a.href = URL.createObjectURL(blob);
        a.click();
        URL.revokeObjectURL(a.href);
      }
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('internet', true, { root: true });
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, { root: true });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<StoreState<IOrganizations>, RootState> = {
  getAllOrganizations: state => state.all,
  getOrganizationDetails: state => state.details,
};

export const organizations: Module<StoreState<IOrganizations>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
